<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-4">
        <MySidebar active="myprofile" :user="body.userName" :image="body.image" />
      </div>
      <div class="col-lg-9 col-md-8">
        <div class="left-profile myproffsf mt-5 pt-4">
          <div class="header-profile">
            <h2>
              {{ $t("my Account") }}
            </h2>

            <button
              class="btn1 btn btn-outline-success"
              @click="$router.push('/editprofile')"
            >
            <img src="../../assets/images/VectorI.png" class="vector"  alt="">
              {{ $t("Data modification") }}

            </button>
          </div>
          <div class="col-lg-6 col-md-12 from-all">
            <Card>
              <template #content>
                <form>
                  <div class="text-center mb-5">
                    <img
                      class="from-img"
                      v-if="body.image"
                      :src="$baseUploadURL + body.image"

                      style="width: 163px; height: 163px; border-radius: 50%"
                    />
                    <img
                      class="from-img"
                      v-else
                      src="/images/user.png"
                    />
                  </div>
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label"
                      >{{ $t("name") }}
                    </label>
                    <input
                      type="name"
                      v-model="body.userName"
                      class="form-control"
                      id="exampleInputEmail1"
                      :placeholder="$t('name')"
                    />

                  </div>
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">
                      {{ $t("E-mail") }}
                    </label>
                    <input
                      type="email"
                      v-model="body.email"
                      class="form-control"
                      id="exampleInputEmail1"
                      :placeholder="$t('E-mail')"
                    />
                    <div id="emailHelp" class="form-text"></div>
                  </div>
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">
                      {{ $t("phone") }}</label
                    >
                    <vue-tel-input
                      placeholder="01000000000 "
                      v-model="body.phone"
                      v-bind="{
                        mode: 'international',
                      }"
                      disabled
                      style="direction: initial"
                    >
                      <template #icon-right>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style="margin-right: 8px; margin-top: 5px"
                          @click="$router.push('/editnumber')"
                        >
                          <path
                            d="M1 18.8654H19M11.7844 3.55424C11.7844 3.55424 11.7844 5.14957 13.419 6.7449C15.0537 8.34023 16.6883 8.34023 16.6883 8.34023M5.31963 15.9259L8.75234 15.4473C9.2475 15.3782 9.70636 15.1543 10.06 14.8091L18.3229 6.7449C19.2257 5.86382 19.2257 4.43531 18.3229 3.55423L16.6883 1.9589C15.7855 1.07783 14.3218 1.07783 13.419 1.9589L5.15616 10.0232C4.80248 10.3683 4.57305 10.8162 4.50231 11.2994L4.01193 14.6496C3.90295 15.3941 4.5568 16.0322 5.31963 15.9259Z"
                            stroke="#F59F00"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                        </svg>
                      </template>
                    </vue-tel-input>
                  </div>
                  <div class="mb-3" @click="$router.push('/changepassword')">
                    <span
                      class="p-input-icon-left p-input-icon-right"
                      style="width: 100%"
                      @click="$router.push('/changepassword')"
                    >
                      <i
                        class="pi pi-chevron-left"
                        style="color: #313131"
                        @click="$router.push('/changepassword')"
                      />
                      <InputText
                        type="text"
                        disabled
                        :value="$t('change password')"
                        style="width: 100%; color: #313131"
                        @click="$router.push('/changepassword')"
                      />
                      <i
                        class="pi pi-lock"
                        style="color: #f59f00"
                        @click="$router.push('/changepassword')"
                      />
                    </span>
                  </div>
                </form>
              </template>
            </Card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      body: {
        userName: null,
        email: null,
        phone: null,
        image: null,
      },
    };
  },
  methods: {},
  async created() {
    this.$http.post("users/getUserById", {}).then((res) => {
      this.body = res.data;
    });
  },
};
</script>
<style>

</style>